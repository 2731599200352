import { css } from 'astroturf';
import Prism from 'prismjs';
import React, { useEffect } from 'react';
import PersonalNote from '../../components/Kilian2';
import Example from './_Example';

import p from '../../components/3dtransforms/style';

// import the Prism package

const styles = css`
  .widerPage {
    padding-top: 5rem;
    text-align: center;
  }
  .exampleTitle {
    margin-top: 8rem !important;
    font-size: 1.5rem !important;
    color: #000 !important;
    text-align: center;

    & a {
      box-shadow: none;
      &::after {
        display: none;
      }
    }
  }
`;

const code1 = `div {
  transform:
    perspective(75em)
    rotateX(18deg);
  box-shadow:
    rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
    rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
  border-radius: 10px;
  border: 1px solid;
  border-color:
    rgb(213, 220, 226)
    rgb(213, 220, 226)
    rgb(184, 194, 204);
}`;

const code2 = `div {
  transform: perspective(1500px) rotateY(15deg);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  transition: transform 1s ease 0s;

  &:hover {
    transform: perspective(3000px) rotateY(5deg);
  }
}`;

const code3 = `div {
  transform:
    rotate3d(.5,-.866,0,15deg)
    rotate(1deg);
  box-shadow:
    2em 4em 6em -2em rgba(0,0,0,.5),
    1em 2em 3.5em -2.5em rgba(0,0,0,.5);
  transition:
    transform .4s ease,
    box-shadow .4s ease;
  border-radius: .5em;

  &:hover {
    transform:
      rotate3d(0,0,0,0deg)
      rotate(0deg);
  }
}`;

const code4 = `div {
  transform:
    perspective(800px)
    rotateY(-8deg);
  transition: transform 1s ease 0s;
  border-radius: 4px;
  box-shadow:
    rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
    rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;

  &:hover {
    transform: perspective(800px) rotateY(-4deg);
  }
}`;

const code5 = `div {
  transform:
    perspective(1000px)
    rotateX(4deg)
    rotateY(-16deg)
    rotateZ(4deg);
  box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}`;

const code6 = `div {
  transform:
    rotateX(51deg)
    rotateZ(43deg);
  transform-style: preserve-3d;
  border-radius: 32px;
  box-shadow:
    1px 1px 0 1px #f9f9fb,
    -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    28px 28px 28px 0 rgba(34, 33, 81, 0.25);
  transition:
    .4s ease-in-out transform,
    .4s ease-in-out box-shadow;

  &:hover {
    transform:
      translate3d(0px, -16px, 0px)
      rotateX(51deg)
      rotateZ(43deg);
    box-shadow:
      1px 1px 0 1px #f9f9fb,
      -1px 0 28px 0 rgba(34, 33, 81, 0.01),
      54px 54px 28px -10px rgba(34, 33, 81, 0.15);
  }
}`;

const code7 = `div {
  transform:
    perspective(2000px)
    translate3d(0px, -66px, 198px)
    rotateX(-55deg)
    scale3d(0.86, 0.75, 1)
    translateY(50px);
  border-radius: 5px;
  will-change: transform;
  transition: 0.4s ease-in-out transform;

  &:hover {
    transform: scale3d(1, 1, 1);
  }
}`;

const code8 = `div {
  transform:
    perspective(750px)
    translate3d(0px, 0px, -250px)
    rotateX(27deg)
    scale(0.9, 0.9);
  border-radius: 20px;
  border: 5px solid #e6e6e6;
  box-shadow: 0 70px 40px -20px rgba(0, 0, 0, 0.2);
  transition: 0.4s ease-in-out transform;

  &:hover {
    transform: translate3d(0px, 0px, -250px);
  }
}`;

const code9 = `div {
  transform:
    perspective(600px)
    rotateX(20deg);
  border-radius: 6px;
}`;

const code10 = `div {
  transform:
    perspective(900px)
    rotateX(60deg)
    scale(0.7);
  box-shadow: 0px 20px 100px #555;
  transition:0.5s ease all;

  &:hover {
    transform:
      rotate(0deg)
      scale(1)
      translateY(10px);
  }
}`;

const code11 = `div {
  transform:
    scale(0.75)
    rotateY(-30deg)
    rotateX(45deg)
    translateZ(4.5rem);
  transform-origin: 50% 100%;
  transform-style: preserve-3d;
  box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.25);
  transition: 0.6s ease transform;

  &:hover {
    transform: scale(1);
  }

  &::before {
    transform: translateZ(4rem);
    &:hover {
      transform: translateZ(0);
    }
  }

  &::after {
    transform: translateZ(-4rem);
    &:hover {
      transform: translateZ(-1px);
    }
  }
}`;

const code12 = `div {
  border-radius: 1em;
  perspective: 600px;
  box-shadow:
    0 0.125em 0.3125em rgba(0, 0, 0, 0.25),
    0 0.02125em 0.06125em rgba(0, 0, 0, 0.25);

  &::before {
    border-radius: 0 0 1em 1em;
    width: 100%;
    height: 50%;
    transform-origin: center top;
    transform: rotateX(180deg);
    background: #333232
      linear-gradient(180deg,
                      rgba(0, 0, 0, 0.1) 50%,
                      rgba(0, 0, 0, 0.4));
    transition: 0.7s ease-in-out transform;
  }

  &:hover::before {
    transform: rotateX(0);
  }
}`;

const code13 = `div {
  transform:
    perspective(800px)
    rotateY(25deg) scale(0.9)
    rotateX(10deg);
  filter: blur(2px);
  opacity: 0.5;
  transition: 0.6s ease all;

  &:hover {
    transform:
      perspective(800px)
      rotateY(-15deg)
      translateY(-50px)
      rotateX(10deg)
      scale(1);
    filter: blur(0);
    opacity: 1;
  }
}`;

const Examples = () => {
  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <>
      <div className={['widePage childPadding', styles.widerPage].join(' ')}>
        <Example
          index="1"
          link="https://modulz.app/styleguide"
          name="Modulz"
          cssStyle={p.no1}
          code={code1}
          codepen="https://codepen.io/polypane/pen/NWYmKKo"
        />
        <Example
          index="2"
          link="https://magicpattern.design/examples"
          name="MagicPattern"
          cssStyle={p.no2}
          code={code2}
          codepen="https://codepen.io/polypane/pen/mdxgbmG"
        />
        <Example
          index="3"
          link="https://workers.cloudflare.com"
          name="Cloudflare"
          cssStyle={p.no3}
          code={code3}
          codepen="https://codepen.io/polypane/pen/jOzRNwO"
        />
        <Example
          index="4"
          link="https://polypane.com"
          name="Polypane"
          cssStyle={p.no4}
          code={code4}
          codepen="https://codepen.io/polypane/pen/YzaMKxv"
        />
        <Example
          index="5"
          link="https://www.wozber.com"
          name="Wozber"
          cssStyle={p.no5}
          code={code5}
          codepen="https://codepen.io/polypane/pen/QWmPLqW"
        />
        <Example
          index="6"
          link="https://draftbit.com"
          name="Draftbit"
          cssStyle={p.no6}
          code={code6}
          codepen="https://codepen.io/polypane/pen/LYdvPze"
        />
        <Example
          index="7"
          link="https://feyapp.com"
          name="Feyapp"
          cssStyle={p.no7}
          code={code7}
          codepen="https://codepen.io/polypane/pen/NWYmKaQ"
        />
        <Example
          index="8"
          link="https://docspo.com"
          name="DocSpo"
          cssStyle={p.no8}
          code={code8}
          codepen="https://codepen.io/polypane/pen/zYWXOPw"
        />
        <Example
          index="9"
          link="https://framer.com"
          name="Framer"
          cssStyle={p.no9}
          code={code9}
          codepen="https://codepen.io/polypane/pen/QWmPLaw"
        />
        <Example
          index="10"
          link="https://codepen.io/akhil_001/pen/zoQdaO"
          name="akhil_001 on CodePen"
          cssStyle={p.no10}
          code={code10}
          codepen="https://codepen.io/polypane/details/oNqOvpq"
        />
        <Example
          index="11"
          link="https://augmented-ui.com"
          name="Augmented UI"
          cssStyle={p.no11}
          code={code11}
          codepen="https://codepen.io/polypane/pen/ZExZzrp"
        />
        <Example
          index="12"
          link="https://pqina.nl/flip/"
          name="Flip by Pqina"
          cssStyle={p.no12}
          code={code12}
          codepen="https://codepen.io/polypane/details/QWmPLrb"
        />
        <Example
          index="13"
          link="https://codepen.io/hqdrone/pen/poyWNqX"
          name="hqdrone on CodePen"
          cssStyle={p.no13}
          code={code13}
          codepen="https://codepen.io/polypane/pen/gOeyYKE"
        />
      </div>
      <PersonalNote />
      <div style={{height: "4rem"}} />
    </>
  );
};
export default Examples;
