import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Link from '../../components/link';
import Layout from '../../layout';
import Examples from './_examples';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Beautiful CSS 3D Transform Perspective Examples in 2024"
          description="Beautiful CSS 3D transform examples using a single div that you can copy with one click!"
          keywords={['']}
          pathname={this.props.location.pathname}
          ogFileName="css3dtransforms"
          ogCustomPath="/og-custom/"
        />
        <PageHeader>
          <h1>Beautiful CSS 3D Transform Examples</h1>
          <h2>
            CSS 3D transforms create depth and visually interesting elements on your page using perspective. Copy over
            the examples and make them your own!
          </h2>
          <p>
            All the examples below have just <strong>a single div</strong>, so they're easy to implement in your own
            project, fill with a background color or HTML content. Examples made with{' '}
            <Link to="/">Polypane, the browser for developers and designers</Link>.
          </p>
        </PageHeader>
        <Examples />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
